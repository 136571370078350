import { replaceRuleStrArr, shouldReplaceArr } from '../const'
import LawKeyword from '../const/lawKeyword'

const LawKwArr = LawKeyword.split('\n')
const LawKwList = []
const LawKwDict = []
LawKwArr.forEach(element => {
  const arr = element.split('$')
  LawKwList.push(arr[0])
  const obj = {
    key: arr[0],
    val: arr[1]
  }
  LawKwDict.push(obj)
})
export default {
  methods: {
    /**
     ** 将 style="font-size:16px;margin-bottom:10px" 按照比例将对应的数值进行处理
     ** 将接口获取到的数据转换成移动端适配渲染需要的格式 目前需要处理的是 pt-px 的相互转换
     */
    transformToRenderDoc(docHtml) {
      const _me = this
      const sized = docHtml.replace(/\d+px+|(\d+\.\d+px)/g, target => {
        const size = target.replace(/px/g, '')
        return parseFloat(size) * _me.ptPr + 'px'
      })
      return sized
    },
    // 通知原生端刷新文书
    refreshNativeWrit() {
      console.log('通知原生端刷新文书')
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        console.log('if true')
        try {
          // eslint-disable-next-line
          AND2JS.refreshWrit()
          console.log('原生方法refreshwrit')
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === this.$ua.i) {
        try {
          // eslint-disable-next-line
          window.webkit.messageHandlers.refreshWrit.postMessage({})
        } catch (err) {
          console.log(err)
        }
      }
    },
    /** 编辑器按像素滚动
     * * 正数则向下滚动 负数向上滚动
     **/
    scrollEditorByPx(px) {
      const selection = this.editor.getSelection()
      if (selection) {
        const { index } = selection
        if (index) {
          const { top } = this.editor.getBounds(index)
          const editorContainer = document.querySelector(
            '.ql-container.ql-snow'
          )
          const editorElem = editorContainer.firstElementChild
          const editorHeight = parseFloat(
            getComputedStyle(editorElem).height.replace(/px/g, ''),
            0
          )
          // const h = editorElem.scrollHeight || 0
          const offsetHeight = parseFloat((px * this.rootFontSize) / 37.5, 0)
          const bh = editorHeight - offsetHeight
          if (top >= bh) {
            /** 向上滚动
             * ! 采用延时操作是因为编辑器容器的 **计算属性变化** 需要一定的时间
             **/
            setTimeout(() => {
              const scrollTop = editorElem.scrollTop || 0
              const newScrollTop = scrollTop + offsetHeight
              editorElem.scrollTop = newScrollTop
            }, 480)
          }
        }
      }
    },
    // 向编辑器指定位置插入文字
    insertText(index, text) {
      this.editor.insertText(index, text)
    },
    // 删除灰色文本
    deleteShadowText(range) {
      const [leaf, offset] = this.editor.getLeaf(range.index)
      const [leafBlock] = this.editor.getLine(range.index)
      const nodeName = leaf.parent.domNode.nodeName
      for (const key in leafBlock.domNode.style) {
        if (key === 'lineHeight' && leafBlock.domNode.style[key]) {
          const elem = document.querySelector('.ql-lineHeight .ql-picker-label')
          if (elem) {
            elem.setAttribute('data-value', leafBlock.domNode.style[key])
          }
        }
      }
      if (nodeName === 'CUSTOM') {
        this.editor.deleteText(range.index - offset, leaf.domNode.length)
      }
    },
    // 注册编辑器 文字选择 监听
    registerSelectionChange() {
      const _me = this
      this.editor.on('selection-change', function(range, oldRange, source) {
        if (range) {
          _me.checkCurrentLineNeedsSuggestion()
          if (range.index) {
            _me.inEditing = true
            const bound = _me.editor.getBounds(range.index)
            const { top, height } = bound
            setTimeout(() => {
              // TODO 根据键盘高度进一步优化
              const editorContainer = document.querySelector(
                '.ql-container.ql-snow'
              )
              const editorElem = editorContainer.firstElementChild
              const editorHeight = parseFloat(
                getComputedStyle(editorElem).height.replace(/px/g, ''),
                0
              )
              const cursorTop = parseFloat(top, 0)
              const scrollTop = editorElem.scrollTop || 0
              const bound = _me.editor.getBounds(range.index)
              console.log('bound=====>', bound)
              // 插入按钮的位置
              const { query } = _me.$route
              const focus = _me.focusText
              if (query.focus && focus) {
                _me.focusBtn.show = true
                _me.focusBtn.top = bound.top
                _me.focusBtn.left = bound.left
                _me.focusBtn.index = range.index
              }
              const newTop = bound.top
              // 需要向下滚动
              if (cursorTop > editorHeight) {
                editorElem.scrollTop = parseInt(
                  scrollTop +
                    (cursorTop - editorHeight) +
                    parseFloat((10 * _me.rootFontSize) / 37.5, 0),
                  0
                )
                // 向上滚动
              } else if (top > newTop) {
                editorElem.scrollTop = parseInt(
                  scrollTop - (top - newTop) - height,
                  0
                )
              }
            }, 480)
            _me.deleteShadowText(range)
          }
          if (range.length === 0) {
            _me.selection = range
            console.log('User cursor is on', range.index)
          } else {
            const text = _me.editor.getText(range.index, range.length)
            _me.selection = range
            console.log('User has highlighted', text)
          }
        } else {
          console.log('Cursor not in the editor')
        }
      })
    },
    registerTextChange() {
      const _me = this
      this.editor.on('text-change', function(delta, oldDelta, source) {
        if (!this.inChangeDocProcess) {
          _me.setEditorKV({ key: 'isCurrentDocumentUnsaved', val: true })
        }
        if (source === 'api') {
          console.log('An API call triggered this change.')
        } else if (source === 'user') {
          console.log('A user action triggered this change.')
        }
      })
    },
    // 匹配法条方法
    findLawPushKeyword(thesaurusList, linestr) {
      // thesaurusList 词库列表 linestr 当前行
      // 从行开始最后依次往上匹配
      for (let j = 1; j <= linestr.length; j++) {
        for (let i = 0; i < thesaurusList.length; i++) {
          if (thesaurusList[i] === linestr.substr(-j, j)) {
            return thesaurusList[i]
          }
        }
      }
      return false
    },
    /**
     * * 逆向匹配 法条关键词库
     * * 取完全匹配到的 长度最长 位置最靠前的词
     * ? 是否可以采用 #包含关键词的方式# 查找 然后取唯一结果进行提示以改善
     */
    findLawKeyword(text) {
      // console.log('法条词库转数组')
      // 法条词库转数组
      const strList = LawKwList
      const len = text.length
      // 存储关键词逆向的组合
      const keywordList = []
      for (let i = len - 1; i > 0; i--) {
        const t = text
        const s = t.slice(i)
        keywordList.push(s)
      }
      /**
       * * 找到的所有可能的法条关键词组合
       * ! 使用Set结构以保证添加的关键词不重复
       */
      const targetList = new Set()
      keywordList.forEach(kw => {
        strList.forEach(law => {
          // TODO 完善匹配方式
          if (law === kw) {
            targetList.add(law)
          }
        })
      })
      /**
       * * 长度最长的法条关键词
       * ? 这样做是否会导致匹配的结果不是预期的
       */
      let maxLen = 1
      // console.log('长度最长的法条关键词')
      if (targetList.length === 1) {
        return targetList[0]
      } else {
        for (const s of targetList.values()) {
          const len = s.length
          if (len > maxLen) {
            maxLen = len
          }
        }
        const longestWords = []
        for (const s of targetList.values()) {
          const len = s.length
          if (len === maxLen) {
            longestWords.push(s)
          }
        }
        if (longestWords.length) {
          // 如果目标关键词有多个则使用当前行 子串最靠前的
          const target = this.findAppearFirst(text, longestWords)
          return target
        } else {
          return null
        }
      }
    },
    /**
     * * 查找一段字符串中最靠前的子串
     */
    findAppearFirst(str, substrs) {
      let itemIndex = 0
      let minIndex = substrs[itemIndex].length
      if (substrs.length === 1) {
        return substrs[itemIndex]
      } else {
        substrs.forEach((s, i) => {
          const index = str.indexOf(s)
          if (index < minIndex && index !== -1) {
            minIndex = index
            itemIndex = i
          }
        })
        return substrs[itemIndex]
      }
    },
    /**
     * * 搜索匹配文本
     * * 采用逆向增长遍历匹配
     */
    search(strList, str) {
      const strs = []
      for (let j = 1; j <= str.length; j++) {
        strs.push(str.substr(-j, j))
      }
      for (let k = 0; k < strs.length; k++) {
        for (let i = 0; i < strList.length; i++) {
          if (strList[i] === strs[k]) {
            return strList[i]
          }
        }
      }
      return false
    },
    calcReplaceVal(lawKw) {
      return LawKwDict.find(item => item.key === lawKw).val || ''
    },
    // 查找当前行是否需要搜索建议
    checkCurrentLineNeedsSuggestion() {
      const index = this.editor.selection.savedRange.index
      // 匹配文字 是否出现搜索列表
      const [line] = this.editor.getLine(index)
      const text = line.domNode.innerText
      if (text.includes(':') || text.includes('：')) {
        const str = text.split(':')
        const str2 = text.split('：')
        if (str.length > 1 || str2.length > 1) {
          // 格式为 str3->原告 strRight->keyword
          const str3 = str.length > 1 ? str[0] : str2.length > 1 ? str2[0] : ''
          const strRight =
            str.length > 1 ? str[1] : str2.length > 1 ? str2[1] : ''
          const keyword = strRight.replace(/\s/g, '')
          if (str3.length > 1 && keyword.length >= 2) {
            // 判断 str3 是否在需要替换的范围内
            if (shouldReplaceArr.indexOf(str3) !== -1 && !this.breakSuggest) {
              this.autoSuggest()
            } else {
              this.closeSuggestionPanel()
            }
          } else {
            this.closeSuggestionPanel()
          }
        } else {
          this.closeSuggestionPanel()
        }
      } else {
        this.closeSuggestionPanel()
      }
    },
    /**
     * * 使用搜索建议替换块级文本
     * ? 需要考虑 怎样找出被替换的区块
     */
    useSuggestion(item) {
      // console.log('use suggestion ----- ')
      const kwLen = this.lawKwStr.length
      const kwVal = LawKwDict.filter(item => item.key === this.lawKwStr)[0].val
      const { itemType } = item
      let name = ''
      /**
       * * 判断item类型
       * ***
       * * CUSTOMER - 当事人
       * * COMPANY - 公司
       * * LAW-SHOW - 替换法条且显示候选
       * * LAW-HIDE - 替换法条且关闭候选
       * ***
       */
      let templateUrl = ''
      if (itemType === 'LAW-SHOW') {
        const replaceIndex = this.index - kwLen
        const replaceText = kwVal + item.text
        const replaceLen = replaceText.length
        // console.log('LAW-SHOW')
        this.editor.deleteText(replaceIndex, kwLen)
        this.editor.insertText(replaceIndex, replaceText)
        this.index = replaceIndex + replaceLen
        this.lawKwStr = ''
        return
      } else if (itemType === 'LAW-HIDE') {
        // console.log('law-hide')
        this.editor.insertText(this.index, item.text)
        setTimeout(() => {
          this.setLawList([])
          this.closeSuggestionPanel()
        }, 200)
        return
      } else if (itemType === 'CUSTOMER') {
        // console.log('CUSTOMER')
        const { customerId } = item
        name = item.name
        templateUrl = `${
          this.$base
        }/document/basicDocuments/getCustomerHtml?customerId=${customerId}&caseId=${
          this.caseId
        }`
      } else if (itemType === 'COMPANY') {
        // console.log('company')
        const { companyKey, companyName, faRen } = item
        name = item.companyName
        templateUrl = `${
          this.$base
        }/document/basicDocuments/getCustomerHtml?companyKey=${companyKey}&companyName=${companyName}&caseId=${
          this.caseId
        }&faRen=${faRen}`
      }

      const nameLen = name.length
      let oldNameLen
      // 因为默认显示原某某 这里减去3则不正确 需要判断是否输入了原某某
      const st = this.editor.getText(this.index - this.strIndex, this.strIndex)
      // console.log('获取的字符', st)
      if (st.indexOf('原告') !== -1) {
        oldNameLen = this.index
      } else {
        oldNameLen = this.index - this.strIndex
        // 步骤1 删除 冒号标签后面的当事人keyword并用
        this.editor.deleteText(oldNameLen, this.strIndex)
      }
      this.editor.insertText(oldNameLen, name)
      // 步骤2 过滤出来最近的关键词 块级区域 将其删除
      const index = oldNameLen + nameLen + 1
      const _replace = replaceRuleStrArr
        .map(item => {
          return {
            name: item,
            index: this.editor.getText(index).indexOf(item)
          }
        })
        .filter(item => {
          return item.index !== -1
        })
        .filter((item, index, arr) => {
          return item.index === Math.min(...arr.map(item => item.index))
        })
      if (_replace.length === 1) {
        this.breakSuggest = true
        const r = _replace[0]
        const blockTitleIndex = oldNameLen + nameLen + 1
        this.editor.setSelection(blockTitleIndex, r.index - 1)
        const lastChar2delete = this.editor.getText(
          blockTitleIndex + r.index - 1,
          1
        )
        const deleteLength =
          lastChar2delete === '\n' ? r.index - 2 : r.index - 3
        setTimeout(() => {
          this.editor.deleteText(blockTitleIndex, deleteLength + 1)
          // 步骤3 将item转换为模板数据进行替换
          this.$axios
            .get(templateUrl)
            .then(res => {
              const {
                data: { data }
              } = res
              // console.log('csetcustomerhtml')
              this.setCustomerHtml(data)
              const insertHtml = this.transformToRenderDoc(
                `${this.customerHtml[0].html}`
              )
              // console.log('inserthtml', insertHtml)
              this.editor.clipboard.dangerouslyPasteHTML(
                blockTitleIndex,
                insertHtml
              )
              this.editor.blur()
              setTimeout(() => {
                this.closeSuggestionPanel()
                this.breakSuggest = false
              }, 300)
            })
            .catch(err => {
              Promise.reject(err)
              setTimeout(() => {
                this.breakSuggest = false
              }, 360)
            })
        }, 300)
      }
    },
    /**
     * 自动推荐 当事人 + 法条智能推荐
     * 需要符合设定的条件才触发
     */
    autoSuggest() {
      const myQuillEditor = this.editor
      if (!myQuillEditor) {
        // console.log('myquilleditor为假')
        return
      }
      const index = myQuillEditor.selection.savedRange.index
      // 匹配文字 是否出现搜索列表
      const [line] = myQuillEditor.getLine(index)
      const text = line.domNode.innerText
      if (index) {
        console.log('index存在', index)
        const { left, top } = myQuillEditor.getBounds(index)
        this.top = top
        this.left = left
        this.index = index

        // 根据前面关键字 检索替换
        if (text.includes(':') || text.includes('：')) {
          const str = text.includes(':') ? text.split(':') : text.split('：')
          if (str.length > 1) {
            // 格式为 strLeft->原告 strRight->keyword
            const strLeft = str[0]
            const strRight = str[1]
            const keyword = strRight.replace(/\s/g, '')
            /**
             ** 关键词的字符长度超过设定条件才执行接口请求
             **/

            var reg = /[\u4E00-\u9FA5]/g
            if (
              strLeft.length > 0 &&
              keyword &&
              keyword.match(reg) &&
              keyword.match(reg).length > 1
            ) {
              // 判断 strLeft 是否在需要替换的范围内
              if (
                shouldReplaceArr.indexOf(strLeft) !== -1 &&
                !this.breakSuggest
              ) {
                this.replaceLabel = strLeft
                this.index = index
                this.strIndex = strRight.length

                // 如果请求当事人 则清空法条数据
                this.setLawList([])
                /**
                 * * 请求两个接口 1.当事人数据 2.工商信息
                 * ! 需要把两个接口返回的数据合并展示
                 */
                // 3个字以内，取客户列表，超过3个字，取公司
                // console.log('三个字以内, 取客户列表')
                if (keyword && keyword.match(reg).length > 3) {
                  // 工商
                  this._debounceGetBusiness(keyword)
                } else {
                  // console.log('替换当事人')
                  // 当事人
                  this._debounceGetCustomer(keyword)
                }
              }
            } else {
              if (this.lawKwStr === '') {
                this.clearSuggestionStoreData()
                this.closeSuggestionPanel()
              }
            }
          }
        } else {
          // 查找法条
          // console.log('查找法条')
          const plainText = text
            .replace(/\s*/g, '')
            .replace(
              /[~|`|!|@|#|$|%|^|&|*|(|)|。|，|,|-|_|+|=|;|:|"|'|,|<|.|>|?]/g,
              ''
            )
          const kw = this.findLawPushKeyword(LawKwList, plainText)
          if (kw) {
            this.lawKwStr = kw
            this._debounceGetLawEntry(kw)
          }
        }
      }
    }
  }
}
