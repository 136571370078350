import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('editor', ['currentDocList'])
  },
  methods: {
    ...mapMutations('editor', ['setCurrentDocList']),
    // 获取文书数据
    async queryDocumentDataAsync(param) {
      this.isLoading = true
      const { data } = await this.$axios.post(
        `${this.$base}/document/basicDocuments/addInformationAndParseHtml`,
        param
      )
      this.isLoading = false
      return data
    },
    // 获取文书组数据
    async queryDocGroupData({ caseId, groupId } = {}) {
      const { data } = await this.$axios({
        method: 'get',
        url: `${this.$base}/document/basicDocuments/getDocumentsByGroupId`,
        params: { caseId, groupId }
      })
      return data
    },
    // 处理页面携带的参数
    async handleEntryPage(param) {
      const { caseId, groupId } = param
      if (!caseId) {
        return this.$notify('缺少必要的案件参数')
      }
      // 有文书组id的情况
      if (groupId) {
        this.groupDataHanlder(groupId, param)
      } else {
        const renderCondition = this.isNormalCase ? 2 : 1
        const { data } = await this.queryDocumentDataAsync({
          ...param,
          renderCondition
        })
        this.docQueryResHandler(data, true)
      }
    },
    // 文书组数据处理
    async groupDataHanlder(groupId, param = {}) {
      const { caseId, documentId, myDocumentId, documentRecordId } = param
      const hasDocSomeId = documentId || myDocumentId || documentRecordId
      const { code, data } = await this.queryDocGroupData({ ...param, groupId })
      if (Number(code) === 200 && Array.isArray(data)) {
        const firstDocEntry = { ...data[0] }
        const {
          documentId,
          myDocumentId,
          litigantId,
          documentRecordId
        } = firstDocEntry
        const renderCondition = this.isNormalCase ? 2 : 1
        const paramByFirstDoc = {
          caseId,
          groupId,
          documentId,
          myDocumentId,
          litigantId,
          documentRecordId,
          renderCondition
        }
        const p = hasDocSomeId
          ? { ...param, renderCondition: 2 }
          : paramByFirstDoc
        const { data: docQueryRes } = await this.queryDocumentDataAsync(p)
        if (docQueryRes) {
          this.docQueryResHandler(docQueryRes, !hasDocSomeId)
        }
        this.forceTopPanelMatchActiveTab(param, data)
        this.groupId = groupId
      } else {
        this.$notify('获取文书数据失败,请重试')
      }
    },
    // 文书数据处理
    async docQueryResHandler(res, updateGroup = false) {
      const {
        caseId,
        litigantId,
        modelVoList,
        name: documentName,
        groupId,
        myDocumentId,
        documentId,
        documentRecordId
      } = res
      const modelId =
        Array.isArray(modelVoList) && modelVoList[0].modelId
          ? modelVoList[0].modelId
          : null
      const html =
        Array.isArray(modelVoList) && modelVoList[0].html
          ? modelVoList[0].html
          : ''
      const type =
        Array.isArray(modelVoList) && modelVoList[0].type
          ? modelVoList[0].type
          : ''

      const docObject = {
        caseId,
        documentId,
        litigantId,
        modelId,
        myDocumentId,
        documentName,
        documentRecordId,
        html,
        type,
        groupId,
        dataType: 'INSERT'
      }
      // 如果需要更新文书组信息
      if (updateGroup && groupId) {
        console.log('需要使用 %s 设置文书组', groupId)
        const param = {
          caseId,
          groupId,
          documentId,
          myDocumentId,
          documentRecordId
        }
        const { code, data } = await this.queryDocGroupData(param)
        if (Number(code) === 200 && Array.isArray(data)) {
          this.forceTopPanelMatchActiveTab(param, data)
          this.groupId = groupId
        }
      }
      this.saveMvvmItems({ documentName, docObject })
    },
    // 切换至文书 若当前文书组内存在则激活tab 否则将该文书添加至该文书组
    switchToDocInCurrGroup(doc) {
      let activeIndex = this.matchTabIndex(doc, this.currentDocList)
      // 当前问数组里已经存在
      if (activeIndex !== null) {
        this.setTopPanelActiveIndex(activeIndex)
        this.setNewContent(doc)
      } else {
        const docs = [...this.currentDocList, doc]
        this.setCurrentDocList(docs)
        activeIndex = docs.length - 1
      }
      this.setTopPanelActiveIndex(activeIndex)
    },
    // 设置文书组信息
    async setDocGroup(payload) {
      const { groupId } = payload
      if (!groupId) {
        return this.$notify('文书组信息不正确')
      }
      console.log('直接设置文书组', payload)
      this.groupDataHanlder(groupId, payload)
      this.showLeftPanel = false
    },
    // 保存需要响应的数据
    saveMvvmItems({ documentName = '', docObject = {} } = {}) {
      this.documentName = documentName
      this.docObject = docObject
    },
    // 匹配下标
    matchTabIndex(bys, data) {
      const { documentId, myDocumentId } = bys
      let matchIndex = null
      if (documentId && myDocumentId) {
        matchIndex = data.findIndex(
          doc =>
            doc.documentId === documentId && doc.myDocumentId === myDocumentId
        )
      } else if (documentId) {
        matchIndex = data.findIndex(doc => doc.documentId === documentId)
      } else if (myDocumentId) {
        matchIndex = data.findIndex(doc => doc.myDocumentId === myDocumentId)
      }
      return matchIndex
    },
    // 让顶部面板组件匹配激活的tab
    forceTopPanelMatchActiveTab(bys = {}, data) {
      console.log('让顶部面板组件匹配激活的tab')
      const index = this.matchTabIndex(bys, data)
      this.setCurrentDocList(data)
      this.setTopPanelActiveIndex(index || 0)
    },
    setTopPanelActiveIndex(index) {
      this.$refs.topPanel && this.$refs.topPanel.forceSetActiveIndex(index)
    }
  }
}
