<template>
  <div class="editor-suggestion-panel">
    <fb-loading :loading="inSearching" />
    <van-cell v-for="(item, i) in customerList"
              class="company-cell"
              :key="i"
              @click.native="useCustomerItem(item)"
              :title="item.customer.name">
      <van-icon slot="right-icon"
                name="contact"
                class="custom-icon" />
    </van-cell>
    <van-cell v-for="item in companyList"
              :key="item.companyKey"
              :title="item.companyName"
              @click.native="useCompanyItem(item)" />
    <div v-for="item in lawList"
         :key="item.title"
         class="van-cell"
         :title="item.title"
         @click.stop="selectLawItem(item)">{{item.title}}
    </div>
    <transition name="van-slide-right">
      <div class="law-object-list"
           v-if="lawObjectList && lawObjectList.length">
        <div v-for="(item, i) in lawObjectList"
             :key="item.law_id + i"
             class="law-obj-item"
             @click.stop="selectLawObject(item)">
          <span class="law-name">{{item.name}}</span>
          <span class="law-text">{{item.text}}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { pydataBase } from '~api-config'
import { mapState } from 'vuex'

export default {
  name: 'suggestion-panel',
  props: {
    height: Number
  },
  data() {
    return {
      loading: false,
      finished: false,
      lawEntry: {},
      lawObjectList: [],
      lawObjetContent: ''
    }
  },
  computed: {
    ...mapState('editor', [
      'inSearching',
      'customerList',
      'lawList',
      'companyList'
    ])
  },
  methods: {
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          const item = `候选结果${this.list.length + 1}`
          this.list.push(item)
        }
        // 加载状态结束
        this.loading = false

        // 数据全部加载完成
        if (this.list.length >= 30) {
          this.finished = true
        }
      }, 500)
    },
    useCustomerItem(item) {
      const payload = item.customer
      payload.itemType = 'CUSTOMER'
      this.$emit('useNewText', payload)
    },
    useCompanyItem(item) {
      const payload = item
      payload.itemType = 'COMPANY'
      this.$emit('useNewText', payload)
    },
    useLawItem(item) {
      const payload = {
        itemType: 'LAW-HIDE',
        text: item.text
      }
      this.$emit('useNewText', payload)
    },
    selectLawItem(item) {
      if (item.title) {
        const payload = {
          itemType: 'LAW-SHOW',
          text: item.title
        }
        if (item.sub_titles && item.sub_titles.length) {
          this.$emit('useNewText', payload)
          this.lawEntry = item
          this.lawObjectList = item.sub_titles
        } else {
          payload.itemType = 'LAW-HIDE'
          this.$emit('useNewText', payload)
        }
      }
    },
    selectLawObject(item) {
      this.useLawItem(item)
    },
    // 获取法条内容
    getLawObjectContent(item) {
      const { title } = this.lawEntry
      const param = title + item.sub_title
      console.log('suggestionpanel')
      console.log(param)
      this.$axios
        .get(`${pydataBase}/api/law/content?law=${param}`)
        .then(res => {
          const { data } = res
          if (data.code === 200) {
            this.lawObjetContent = data.result.text
          }
        })
        .catch(err => {
          Promise.reject(err)
        })
    }
  }
}
</script>

<style lang="stylus">
.editor-suggestion-panel
  height 88px
  background #ffffff
  box-shadow 0px 3px 15px 1px rgba(204, 204, 204, 0.45)
  z-index 10
  overflow-y scroll
  -webkit-overflow-scrolling touch
  position relative
  .company-cell
    &:active, :visited
      background #ececec
  .law-object-list, .law-object-object
    position absolute
    height 88px
    width 100%
    top 0
    left 0
    z-index 2
    background #ffffff
    overflow-y scroll
    -webkit-overflow-scrolling touch
    .law-obj-item
      font-size 14px
      padding 5px
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
  .law-object-object
    z-index 3
    .law-object-text
      padding 10px
      font-size 12px
</style>
