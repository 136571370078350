<template>
  <div class="top-panel van-hairline--bottom">
    <div class="more-menu" @click="showMenu">
      <i class="iconfont icon-caozuo"></i>
    </div>
    <div class="back-menu" @click="back">
      <i class="iconfont icon-back"></i>
    </div>
    <div class="doc-group" v-if="showCurrentDocListTab && currentDocList.length">
      <div class="loading" v-if="isLoadingDocGroup">
        <van-loading />
      </div>
      <template v-else>
        <van-tabs
          v-model="activeTabIndex"
          ref="tabsRef"
          @click="onDocClick"
          class="doc-group-tabs"
          :line-height="0"
          :line-width="0"
          :ellipsis="false"
        >
          <van-tab v-for="(doc, index) in currentDocList" :key="index">
            <div slot="title" class="tab-title">
              <span class="tab-name">{{ doc.documentName }}</span>
            </div>
          </van-tab>
        </van-tabs>
      </template>
    </div>
    <div class="tip" v-else>
      <div class="doc-name" v-if="documentName">{{ documentName }}</div>
      <!-- <div v-else><i class="iconfont icon-jinggao2"></i> 暂无文书组</div> -->
    </div>
  </div>
</template>

<script>
import { caseBase } from '~api-config'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'top-panel',
  props: {
    docGroupId: [String, Number, Boolean],
    caseId: Number,
    documentName: String,
    defaultGroupId: [String, Number, Boolean],
    defaultDocumentId: [String, Number, Boolean],
    activeDoc: [Object, Boolean],
  },
  data() {
    return {
      activeTabIndex: null,
      hasCase: false,
      shouldUseDefaultGroup: true,
      // 等待切换的文书入口数据
      waitDocumentEntry: null,
      shouldMatchDoc: false,
      shouldMatchDocOnlyByDocumentId: false,
      isLoadingDocGroup: false,
    }
  },
  computed: {
    ...mapState('editor', [
      'currentDocList',
      'showCurrentDocListTab',
      'pushTime',
      'pushedDocument',
      'isCurrentDocumentUnsaved',
    ]),
  },
  methods: {
    ...mapMutations('editor', [
      'setCurrentDocList',
      'setShowCurrentDocListTab',
      'setEditorKV',
    ]),
    // 文书组的文书点击
    onDocClick(index) {
      const documentEntry = this.currentDocList[index]
      if (documentEntry) {
        this.$emit('selectDoc', documentEntry)
      }
    },
    // 强制设置激活的下标
    forceSetActiveIndex(index) {
      this.activeTabIndex = index
      this.setShowCurrentDocListTab(false)
      this.$nextTick(() => {
        this.setShowCurrentDocListTab(true)
        this.$refs.tabsRef && this.$refs.tabsRef.resize()
      })
    },
    // 2020-11-20 改函数不再做任何事情 数据的获取交给父组件
    getDocGroupDocs() {},
    getDocGroupDocsOld(groupId, useFirst = false) {
      if (this.caseId) {
        this.isLoadingDocGroup = true
        this.$axios({
          method: 'get',
          url: `${caseBase}/document/basicDocuments/getDocumentsByGroupId`,
          params: {
            caseId: this.caseId,
            groupId,
          },
        })
          .then((res) => {
            const { data } = res.data
            if (data.length) {
              /**
               * * 使用setShowCurrentDocListTab重新渲染 #vant tab#
               * ! 之所以这样处理应该是因为 #vant tabs# 组件不能直接更新传递的数据
               **/
              this.setShowCurrentDocListTab(false)
              setTimeout(() => {
                this.setCurrentDocList(this.addGroupId(data, parseInt(groupId, 0)))
                this.setShowCurrentDocListTab(true)
                if (this.shouldMatchDoc) {
                  console.log('shouldMatchDoc --> ', this.shouldMatchDoc)
                  this.matchActiveDocTab()
                } else if (this.shouldMatchDocOnlyByDocumentId) {
                  this.matchActiveDocTabOnlyByDocumentId()
                }
                // 使用文书组中的第一条数据通知父组件 更新编辑器内容
                if (useFirst) {
                  console.log('使用文书组中的第一条数据通知父组件')
                  const documentEntry = this.currentDocList[0]
                  this.activeTabIndex = 0
                  documentEntry.disableEditor = false
                  if (documentEntry) {
                    this.$emit('getDocData', documentEntry)
                  }
                }
              }, 20)

              setTimeout(() => {
                this.isLoadingDocGroup = false
                this.matchActiveDocTab()
              }, 500)
            }
          })
          .catch((err) => {
            this.$notify('获取文书组失败')
            this.isLoadingDocGroup = false
            Promise.reject(err)
          })
      }
    },
    matchActiveDocTab(forceWait = false) {
      this.shouldMatchDocOnlyByDocumentId = false
      if (forceWait) {
        this.shouldMatchDoc = true
      } else {
        if (this.activeDoc) {
          const data = this.currentDocList
          let matchedIndex = null
          if (data && data.length) {
            data.forEach((doc, index) => {
              if (
                doc.documentId === this.activeDoc.documentId &&
                doc.myDocumentId === this.activeDoc.myDocumentId
              ) {
                matchedIndex = index
              }
            })
            if (matchedIndex && matchedIndex !== this.activeTabIndex) {
              this.activeTabIndex = matchedIndex
              this.shouldMatchDoc = false
            }
          } else {
            this.shouldMatchDoc = true
          }
        }
      }
    },
    matchActiveDocTabOnlyByDocumentId(forceWait = false) {
      this.shouldMatchDoc = false
      if (forceWait) {
        this.shouldMatchDocOnlyByDocumentId = true
      } else {
        if (this.activeDoc) {
          const data = this.currentDocList
          let matchedIndex = null
          if (data && data.length) {
            data.forEach((doc, index) => {
              if (doc.documentId === this.activeDoc.documentId) {
                matchedIndex = index
              }
            })
            if (matchedIndex && matchedIndex !== this.activeTabIndex) {
              this.activeTabIndex = matchedIndex
              this.shouldMatchDocOnlyByDocumentId = false
            }
          } else {
            this.shouldMatchDocOnlyByDocumentId = true
          }
        }
      }
    },
    addGroupId(arr, groupId) {
      return arr.map((item) => {
        item.groupId = groupId
        return item
      })
    },
    showMenu() {
      this.$emit('toggleMoreMenu')
    },
    back() {
      this.$emit('back')
    },
    // 匹配激活的文书tab
    matchactiveTabIndexIndex(docId) {
      const data = this.currentDocList
      let matchedIndex = null
      if (data && data.length) {
        data.forEach((doc, index) => {
          if (doc.documentId === docId || doc.myDocumentId === docId) {
            matchedIndex = index
          }
        })
        if (matchedIndex) {
          this.activeTabIndex = matchedIndex
        }
      }
    },
    checkExist(doc) {
      if (this.currentDocList && this.currentDocList.length) {
        const arr = this.currentDocList.filter(
          (item) => item.documentId === doc.documentId
        )
        if (arr.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    pushDocument() {
      const data = this.currentDocList
      const doc = this.pushedDocument
      // 当前文书组不存在则添加进去
      if (!this.checkExist(doc)) {
        data.push(doc)
        this.setShowCurrentDocListTab(false)
        setTimeout(() => {
          this.setCurrentDocList(data)
          this.setShowCurrentDocListTab(true)
          this.matchactiveTabIndexIndex(doc.documentId)
        }, 20)
        // 否则
      } else {
        this.matchactiveTabIndexIndex(doc.documentId)
      }
    },
  },
  mounted() {},
  created() {
    const fromType = this.$route.query.fromType
    if (fromType === 'NOCASE') {
      this.hasCase = false
    } else {
      this.hasCase = true
    }
  },
  watch: {
    // defaultGroupId (nv, ov) {
    //   if (nv && nv !== '') {
    //     this.shouldUseDefaultGroup = true
    //     this.getDocGroupDocs(nv, false)
    //   }
    // },
    defaultDocumentId(nv, ov) {
      if (nv) {
        this.matchActiveDocTabOnlyByDocumentId()
      }
    },
    pushTime(nv, ov) {
      if (nv) {
        this.pushDocument()
      }
    },
    // activeDoc (nv, ov) {
    //   console.log('ov:', ov)
    //   if (ov.documentId === null && ov.myDocumentId === null && ov.documentRecordId === null) {
    //     if (nv.documentId || nv.myDocumentId || nv.documentRecordId) {
    //       this.matchActiveDocTab()
    //     }
    //   }
    // }
  },
}
</script>

<style lang="stylus">
.top-panel
  height 46px
  z-index 1
  background #ffffff
  width 100%
  top 0
  position relative
  & .doc-group
    display flex
    align-items center
    justify-content center
    height 100%
    & .doc-group-tabs.van-tabs
      & .van-hairline--top-bottom
        &::after
          display none
      .van-tabs__wrap
        .van-tabs__nav
          width calc(100% - 80px) !important
          left 40px
          .van-tab
            div.tab-title
              span.tab-name
                background rgba(231, 231, 231, 1)
                padding 6px 8px
                display inline
                color #333
                border-radius 5px
          .van-tab.van-tab--active
            div.tab-title
              & span.tab-name
                background #1989fa
                color #ffffff
                display inline
                padding 6px 8px
                border-radius 5px
                font-size 14px
                font-weight 200
                font-family PingFangSC
  .tip
    display flex
    justify-content center
    align-items center
    height 44px
    color #ff976a
    font-size 15px
    i
      margin-right 5px
    .doc-name
      color #333
      font-weight bold
  .more-menu, .back-menu
    position absolute
    top 0
    z-index 1111
    width 40px
    height 46px
    display flex
    justify-content center
    align-items center
    &:active
      background #f3f4f7
    i
      font-size 20px
      color #a7a7a7
  .more-menu
    right 0
  .back-menu
    left 0
</style>
