var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview-modal" }, [
    _c(
      "div",
      { staticClass: "paper-wrapper", style: _vm.wrapperStyle },
      [
        _c(
          "quill-editor",
          {
            ref: "editor",
            style: _vm.paperStyle,
            attrs: {
              id: "preview-editor",
              content: _vm.htmlStr,
              options: _vm.editorOption,
            },
          },
          [
            _c("div", {
              attrs: { slot: "toolbar", id: "preview-editor-toolbar" },
              slot: "toolbar",
            }),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "control-panel" }, [
      _c("div", { staticClass: "zoom-out", on: { click: _vm.zoomOut } }, [
        _c("i", { staticClass: "iconfont icon-fangda" }),
      ]),
      _c("div", { staticClass: "zoom-in", on: { click: _vm.zoomIn } }, [
        _c("i", { staticClass: "iconfont icon-suoxiao" }),
      ]),
      _c("div", { staticClass: "exit", on: { click: _vm.exit } }, [
        _c("i", { staticClass: "iconfont icon-ios-my-exit" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }