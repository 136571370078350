export default {
  computed: {
    // 左侧面板样式
    leftPanelStyle() {
      if (this.showLeftPanel) {
        return {
          transform: 'translate3d(0, 0, 0)'
        }
      } else {
        return {
          transform: 'translate3d(-100%, 0, 0)'
        }
      }
    },
    // 右侧面板样式
    rightPanelStyle() {
      if (this.showRightPanel) {
        return {
          transform: 'translate3d(0, 0, 0)'
        }
      } else {
        return {
          transform: 'translate3d(100%, 0, 0)'
        }
      }
    },
    // 编辑器容器样式
    editorWrapperStyle() {
      let height = 5
      if (this.showSuggestionPanel) {
        height += 88
      }
      if (this.focusBtn.show) {
        height += 30
      }
      console.log(height)
      if (height > 10) {
        height = height / 37.5
        return {
          height: `calc(100vh - ${height}rem)`
        }
      } else {
        return {
          height: '100%'
        }
      }
    },
    // 编辑器顶部样式
    editorHeaderStyle() {
      if (this.showTopPanel) {
        return {
          top: '0'
        }
      } else {
        return {
          top: '0'
        }
      }
    },
    // 案由选择组件样式
    reasonPickerStyle() {
      const h = document.documentElement.clientHeight
      const w = document.documentElement.clientWidth
      return {
        width: `${w}px`,
        height: `${h}px`
      }
    }
  }
}
