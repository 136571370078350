/**
 * 接受html
 * 压缩html
 * * 去除html标签中的空白等
 */
export default html => {
  const source = html
  const rep = /\n*/g
  const repone = /<!--.*?-->/gi
  const reptwo = /\/\*.*?\*\//gi
  const repthree = /\s*</gi
  const repfour = />\s*/gi

  const sourceZero = source.replace(rep, '')
  const sourceOne = sourceZero.replace(repone, '')
  const sourceTwo = sourceOne.replace(reptwo, '')
  const sourceThree = sourceTwo.replace(repthree, '<')
  const sourceFour = sourceThree.replace(repfour, '>')
  return sourceFour
}
