export default `《$
，依据$，依据
。依据$。依据
 依据$ 依据
故依据$故依据
所以依据$所以依据
现在依据$现在依据
我们依据$我们依据
因为依据$因为依据
应当依据$应当依据
依据《中$依据
依据中华$依据
，根据$，根据
。根据$。根据
 根据$ 根据
故根据$故根据
所以根据$所以根据
我们根据$我们根据
现在根据$现在根据
因为根据$因为根据
应当根据$应当根据
根据《中$根据
根据中华$根据
。依照$。依照
，依照$，依照
 依照$ 依照
故依照$故依照
因为依照$因为依照
所以依照$所以依照
我们依照$我们依照
现在依照$现在依照
应当依照$应当依照
依照《中$依照
依照中华$依照
，按照$，按照
。按照$。按照
 按照$ 按照
故按照$故按照
因为按照$因为按照
所以按照$所以按照
现在按照$现在按照
应当按照$应当按照
按照《中$按照
按照中华$按照
按《中$按
按中华人民$按
行为触犯$行为触犯
行为属于$行为属于
并不违反$并不违反
行为违反$行为违反
依据最高$依据
按照最高$按照
依照最高$依照
根据最高$根据
根据《最高$根据
依据《最高$依据
按照《最高$按照
依照《最高$依照
了《中华人民$了
违反了《中华人民$违反了
关于《审理$
关于审理$
同时根据$同时根据
符合《中华人$符合
关于适用$关于适用
关于适用《中华$关于适用
最高人民法院适用$
最高院关于$最高院关于
最高院发布$最高院发布
完全符合《中华$完全符合
完全符合中华$完全符合
中华人民共和国$
最高人民法院$
最高人民检察院$
全国人民代表大会$
全国人大$
全国人大常务委员会$
全国人大常委会$
主席令$
最高人民检察署$
刑法$
刑法修正案$
公司法$
劳动法$
合同法$
行政诉讼法$
行诉法$
劳动合同法$
民事诉讼法$
民诉法$
组织法$
民法通则$
物权法$
民法总则$
婚姻法$
侵权责任法$
担保法$
著作权法$
保险法$
建筑法$
反不正当竞争法$
交通安全法$
证券投资基金法$
劳动争议调解仲裁法$
消费者权益保护法$
城乡规划法$
产品质量法$
破产法$
政府采购法$
继承法$
商标法$
社会保险法$
食品安全法$
海关法$
税收征收法$
税法$
治安处罚法$
行政处罚法$
企业国有资产法$
国有资产法$
国资法$
合伙企业法$
仲裁法$
银行法$
商业银行法$
证券法$
专利法$
电子签名法$
国家赔偿法$
涉外民事关系法$
涉外适用法$
行政复议法$
环境保护法$
中外合资经营企业法$
网络安全法$
行政强制法$
计划生育法$
未成年人保护法$
公路法$
律师法$
票据法$
妇女权益保护法$
安全生产法$
行政许可法$
农业法$
电影产业促进法$
企业所得税法$
药品管理法$
外资企业法$
水法$
老年人权益保障法$
职业病防治法$
档案法$
环境防治法$
民用航空法$
公务员法$
森林法$
立法法$
教育促进法$
反间谍法$
海商法$
宪法$
价格法$
反垄断法$
鉴定管理问题的决定$
信托法$
工会法$
公证法$
土壤污染防治法$
水污染防治法$
污染环境防治法$
职业病防治法$
民族区域自治法$
传染病防治法$
`
