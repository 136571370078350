export default {
  methods: {
    // 编辑器工具栏手势 --start--
    ontouchstart (e) {
      this.startTime = new Date()
      var t = e.touches ? e.touches[0] : e
      this.startPoint = { x: t.pageX, y: t.pageY }
    },
    ontouchmove (e) {
      const t = e.touches ? e.touches[0] : e
      const p = { x: t.pageX, y: t.pageY }
      this.currentPoint = p
      const x1 = this.startPoint.x
      const x2 = this.currentPoint.x
      const y1 = this.startPoint.y
      const y2 = this.currentPoint.y
      if (Math.abs(x1 - x2) > 2 || Math.abs(y1 - y2) > 2) {
        // 判断滑动方向
        const direction = this.getSwipeDirection(x1, x2, y1, y2)
        if (direction === 'up') {
          this.isTouching = true
          this.debounce(this.hideTopPanelAction(), 400)
        } else if (direction === 'down') {
          this.isTouching = true
          this.debounce(this.showTopPanelAction(), 400)
          this.onPullDown()
        } else {
          this.isTouching = true
        }
      }
    },
    ontouchend (e) {
      this.isTouching = false
    },
    // 编辑器工具栏手势 --end--
    // 编辑器内容区域手势 --start--
    onEtouchstart (e) {
      this.startTime = new Date()
      var t = e.touches ? e.touches[0] : e
      this.startPoint = { x: t.pageX, y: t.pageY }
    },
    onEtouchmove (e) {
      const t = e.touches ? e.touches[0] : e
      const p = { x: t.pageX, y: t.pageY }
      this.currentPoint = p
      const x1 = this.startPoint.x
      const x2 = this.currentPoint.x
      const y1 = this.startPoint.y
      const y2 = this.currentPoint.y
      if (Math.abs(x1 - x2) > 2 || Math.abs(y1 - y2) > 2) {
        // 判断滑动方向
        const direction = this.getSwipeDirection(x1, x2, y1, y2)
        if (direction === 'right') {
          this.isTouching = true
          this.debounce(this.showLeftPanelAction(), 300)
        } else if (direction === 'left') {
          this.isTouching = true
          this.debounce(this.showRightPanelAction(), 300)
        } else {
          this.isTouching = true
        }
      }
    },
    onEtouchend (e) {
      this.isTouching = false
    },
    // 编辑器内容区域手势 --end--
    // 编辑器遮罩 --start--
    onMaskTouchstart (e) {
      this.startTime = new Date()
      var t = e.touches ? e.touches[0] : e
      this.startPoint = { x: t.pageX, y: t.pageY }
    },
    onMaskTouchmove (e) {
      const t = e.touches ? e.touches[0] : e
      const p = { x: t.pageX, y: t.pageY }
      this.currentPoint = p
      const x1 = this.startPoint.x
      const x2 = this.currentPoint.x
      const y1 = this.startPoint.y
      const y2 = this.currentPoint.y
      if (Math.abs(x1 - x2) > 2 || Math.abs(y1 - y2) > 2) {
        // 判断滑动方向
        const direction = this.getSwipeDirection(x1, x2, y1, y2)
        if (direction === 'up') {
          this.isTouching = true
          this.debounce(this.hideMaskAction(), 400)
        } else {
          this.isTouching = true
        }
      }
    },
    onMaskTouchend (e) {
      this.isTouching = false
    },
    hideMaskAction () {
      this.hideMask()
    }
  }
}
