export default {
  methods: {
    // 设置粗体
    setBold() {
      if (this.isTouching) return
      this.inStyling = true
      const { index, length } = this.selection
      const format = this.editor.getFormat(index, length)
      if (length) {
        this.editor.formatText(index, length, 'bold', !format.bold)
      }
      setTimeout(() => {
        this.inStyling = false
      }, 200)
    },
    // 设置斜体
    setItalic() {
      if (this.isTouching) return
      this.inStyling = true
      const { index, length } = this.selection
      const format = this.editor.getFormat(index, length)
      if (length) {
        this.editor.formatText(index, length, 'italic', !format.italic)
      }
      setTimeout(() => {
        this.inStyling = false
      }, 200)
    },
    /**
     * * 清除选中文本的背景色
     * * 文本选中的背景色是为了辅助用户操作 在设置完样式后应该清除掉
     **/
    clearSelcetionTextBG() {
      const totalLen = this.editor.getLength()
      this.editor.formatText(0, totalLen, 'background', '')
    },
    // 设置下划线
    setUnderline() {
      if (this.isTouching) return
      this.inStyling = true
      const { index, length } = this.selection
      const format = this.editor.getFormat(index, length)
      if (length) {
        this.editor.formatText(index, length, 'underline', !format.underline)
      }
      setTimeout(() => {
        this.inStyling = false
      }, 200)
    },
    // 设置字号
    setFontSize() {
      if (this.isTouching) return
      this.inStyling = true
      const { index, length } = this.selection
      this.selectionFormat = this.editor.getFormat(index, length)
      if (this.selection.length) {
        setTimeout(() => {
          this.showFontSizePicker = true
        }, 305)
      } else {
        this.$notify('需要选中文本才能设置字号')
      }
      setTimeout(() => {
        this.inStyling = false
      }, 200)
    },
    // 选择字号
    onSelectFontSize(item) {
      if (this.isTouching) return
      this.inStyling = true
      const { index, length } = this.selection
      const size = this.calcRenderFontSizeInPx(item.value)
      if (length && item && item.value) {
        this.editor.formatText(index, length, 'size', `${size}px`)
      }
      // this.editor.setSelection(this.selection)
      this.showFontSizePicker = false
      setTimeout(() => {
        this.inStyling = false
      }, 200)
    },
    /**
     * * 由于页面进行了rem转换需要将内联样式中的pt进行转换
     * ? 是否有更好的方式
     * ! 保存文档时务必要将内联样式中的字体转换回去
     * @prama px 根据 rootFontSize 进行转换
     **/
    calcRenderFontSizeInPx(px) {
      return px * (this.rootFontSize / 37.5)
    },
    // 还原
    redo() {
      this.editor.history.redo()
    },
    // 撤销
    undo() {
      this.editor.history.undo()
    },
    // #保存#按钮操作
    save() {
      this.module = false
      this.handleHtmlChangeV2()
    }
  }
}
