import Qs from 'qs'
export default {
  data() {
    return {
      htmlChange: {
        htmlCode: 0,
        htmlMsg: '',
        htmlLitigant: null,
        caseLitigant: null
      },
      caseReasonChange: {
        caseReasonCode: 0,
        caseReasonMsg: '',
        htmlCaseReason: null,
        caseCaseReason: null,
        recommend: null
      },
      pickedParseReason: null,
      pickedLitigant: [],
      pickedRecommendReason: null,
      showOtherReasonPicker: false
    }
  },
  computed: {
    // 所有当事人
    allLitigants() {
      // console.log('所有当事人')
      if (
        !this.htmlChange ||
        !this.htmlChange.changeLitigants ||
        !this.htmlChange.changeLitigants.length
      ) {
        // console.log('return []')
        return []
      } else {
        const arr = []
        this.htmlChange.changeLitigants.forEach(item => {
          const {
            procedureSubjectEnum,
            caseLitigantName,
            htmlLitigantName,
            litigantType
          } = item
          if (caseLitigantName && caseLitigantName.length) {
            caseLitigantName.forEach(item => {
              const p = {
                name: item,
                procedureSubjectEnum,
                litigantType
              }
              if (!this.checkExistInLitigantList(arr, p)) {
                arr.push(p)
              }
            })
          }
          if (htmlLitigantName && htmlLitigantName.length) {
            htmlLitigantName.forEach(item => {
              const p = {
                name: item,
                procedureSubjectEnum,
                litigantType
              }
              if (!this.checkExistInLitigantList(arr, p)) {
                arr.push(p)
              }
            })
          }
        })
        // console.log('return arr')
        return arr
      }
    },
    // 选择的当事人
    pickedLitigantForSave() {
      // console.log('选择的当事人')
      if (!this.pickedLitigant || !this.pickedLitigant.length) {
        return []
      } else {
        const litigant = {}
        this.pickedLitigant.forEach(item => {
          const t = item.split(' - ')
          if (!litigant[t[0]]) {
            litigant[t[0]] = []
          }
          litigant[t[0]].push(t[1])
        })
        const arr = []
        Object.keys(litigant).forEach(key => {
          const s = litigant[key].join('、')
          const p = {
            procedureSubjectEnum: key,
            caseLitigantName: s
          }
          arr.push(p)
        })
        return arr
      }
    }
  },
  methods: {
    checkReasonExist(name, reasons) {
      let exist = false
      // 如果案由数据为空则直接返回
      if (!reasons || !reasons.length) {
        return false
      } else {
        reasons.forEach(item => {
          if (item.summary_name === name) {
            exist = true
          }
        })
        return exist
      }
    },
    // TODO 根据用户选择的值 进行操作
    confirmChangePick(updateCaseInfo = false) {
      if (updateCaseInfo) {
        // 先判断案由是否有改动
        const caseReasonChange = this.caseReasonChange
        const reasonChanged =
          caseReasonChange &&
          (caseReasonChange.caseReasonCode === 1 ||
            caseReasonChange.caseReasonCode === 4)
        // 如果案由没有改动 则保存文书并更新案件共享信息
        if (!reasonChanged) {
          console.log('案由没有改动 -> 保存并更新共享信息')
          this.saveDocUseParamWithDisplayupdate(1)
        } else {
          console.log('案由改动 -> 判断用户选择案由与案件原案由是否相同')
          const _me = this
          this.isLoading = true
          let r = null
          if (this.pickedRecommendReason) {
            r = this.pickedRecommendReason
          } else if (this.pickedParseReason) {
            r = this.pickedParseReason
          }
          /** 判断用户选择的案由是否和当前案件案由相同
           * ! 如果相同则需要替换当前文书内的案由为案件案由
           * * 调用保存接口前需要检查当事人是否变动
           */
          if (r && r === this.causeName && r !== '') {
            console.log('用户选择的案由与原案件案由相同')
            const doc = JSON.parse(JSON.stringify(this.docObject))
            doc.html = this.docToSave.replace(
              new RegExp(_me.caseReasonChange.htmlCaseReason, 'g'),
              r
            )
            // this.docObject = doc
            const htmlChange = this.htmlChange
            const { htmlCode } = htmlChange
            const isLitigantSlientUpdate =
              htmlCode === 0 || htmlCode === 5 || htmlCode === 1
            if (isLitigantSlientUpdate) {
              console.log('用户选择后 保存并更新共享信息')
              this.saveDocUseParamWithDisplayupdate(1)
            } else {
              console.log('用户选择后 保存不更新共享信息')
              this.saveDocUseParamWithDisplayupdate(0)
            }
          } else if (r && r !== this.causeName) {
            console.log('用户选择的案由与原案件案由不同')
            const params = {
              caseId: this.caseId,
              causeName: r
            }
            this.updateCaseReason(params)
              .then(res => {
                const { data } = res
                if (data.code === '200' && data.message === 'OK') {
                  // 案由修改后 重新获取案件基本信息
                  this.getCaseBasic()
                } else {
                  this.$notify('更新案由失败')
                }
                console.log('用户选择后 -> 更新案由 -> 保存并更新共享信息')
                this.saveDocUseParamWithDisplayupdate(1)
              })
              .catch(err => {
                console.log('用户选择后 -> 更新案由失败 -> 保存并更新共享信息')
                this.saveDocUseParamWithDisplayupdate(1)
                console.log(err)
              })
          } else {
            console.log('选择的案由数据为：', r)
            this.saveDocUseParamWithDisplayupdate(1)
          }
        }
      } else {
        console.log('用户选择直接保存 -> 保存不更新共享信息')
        this.saveDocUseParamWithDisplayupdate(0)
      }
    },
    // 重置getChangeV2接口各项的值
    resetChangePickerData() {
      this.pickedRecommendReason = null
      this.pickedParseReason = null
      this.showOtherReasonPicker = false
      this.pickedLitigant = []
    },
    // 重置检测文本内容变化函数所需要的初始化数据
    resetChangeV2Data() {
      this.htmlChange = {
        htmlCode: 0,
        htmlMsg: '',
        htmlLitigant: null,
        caseLitigant: null
      }
      this.caseReasonChange = {
        caseReasonCode: 0,
        caseReasonMsg: '',
        htmlCaseReason: null,
        caseCaseReason: null,
        recommend: null
      }
    },
    // 案由选择触发
    handleReasonSelect(payload) {
      console.log('案由选择触发')
      const { displayUpdate } = payload
      if (displayUpdate) {
        console.log('用户选择 保存并更新共享信息')
        this.confirmChangePick(true)
      } else {
        console.log('用户选择 直接保存')
        this.confirmChangePick(false)
      }
    },
    // -- 改动检测popup相关--
    handleReasonChange(reasonText) {
      console.log('案由改动为：', reasonText)
      if (this.pickedParseReason) {
        this.pickedParseReason = reasonText
      } else {
        this.pickedRecommendReason = reasonText
      }
    },
    async parseReason() {
      const loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: 'circular',
        message: '正在解析案由',
        mask: true
      })
      const params = {
        keyword: this.docPlain
      }
      const { data } = await this.$http({
        method: 'post',
        url: `${this.$pydataBase}/api/tree/cal_similitude/sid`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        data: Qs.stringify(params)
      })
      loading.clear()
      return data
    }
  }
}
