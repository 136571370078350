// 自定义字号
export const fontSizeArr = [
  {
    name: '一号',
    value: '26'
  },
  {
    name: '小一',
    value: '24'
  },
  {
    name: '二号',
    value: '22'
  },
  {
    name: '小二',
    value: '24'
  },
  {
    name: '三号',
    value: '16'
  },
  {
    name: '小三',
    value: '15'
  },
  {
    name: '四号',
    value: '14'
  },
  {
    name: '小四',
    value: '12'
  },
  {
    name: '五号',
    value: '10.5'
  },
  {
    name: '小五',
    value: '9'
  }
]

export const nomalFontSizeInPX = [
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32
]

export const editorOption = {
  // quill options
  modules: {
    toolbar: '#editor-toolbar',
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: false
    },
    clipboard: {
      matchVisual: false
    },
    // 表格插件开关
    table: true
  },
  placeholder: ''
}

export const shouldReplaceArr = [
  '原告',
  '被告',
  '上诉人',
  '申请人',
  '被上诉人',
  '被申请人',
  '申诉人',
  '被申诉人',
  '请求事项',
  '事实和理由',
  '事实理由',
  '诉讼请求'
]

const tempArr = []

shouldReplaceArr.forEach(item => {
  tempArr.push(item + ':')
  tempArr.push(item + '：')
})

export const replaceRuleStrArr = tempArr
