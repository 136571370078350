var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "top-panel van-hairline--bottom" }, [
    _c("div", { staticClass: "more-menu", on: { click: _vm.showMenu } }, [
      _c("i", { staticClass: "iconfont icon-caozuo" }),
    ]),
    _c("div", { staticClass: "back-menu", on: { click: _vm.back } }, [
      _c("i", { staticClass: "iconfont icon-back" }),
    ]),
    _vm.showCurrentDocListTab && _vm.currentDocList.length
      ? _c(
          "div",
          { staticClass: "doc-group" },
          [
            _vm.isLoadingDocGroup
              ? _c("div", { staticClass: "loading" }, [_c("van-loading")], 1)
              : [
                  _c(
                    "van-tabs",
                    {
                      ref: "tabsRef",
                      staticClass: "doc-group-tabs",
                      attrs: {
                        "line-height": 0,
                        "line-width": 0,
                        ellipsis: false,
                      },
                      on: { click: _vm.onDocClick },
                      model: {
                        value: _vm.activeTabIndex,
                        callback: function ($$v) {
                          _vm.activeTabIndex = $$v
                        },
                        expression: "activeTabIndex",
                      },
                    },
                    _vm._l(_vm.currentDocList, function (doc, index) {
                      return _c("van-tab", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "tab-title",
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [
                            _c("span", { staticClass: "tab-name" }, [
                              _vm._v(_vm._s(doc.documentName)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    1
                  ),
                ],
          ],
          2
        )
      : _c("div", { staticClass: "tip" }, [
          _vm.documentName
            ? _c("div", { staticClass: "doc-name" }, [
                _vm._v(_vm._s(_vm.documentName)),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }