export default {
  methods: {
    // 计算滑动方向
    getSwipeDirection (x1, x2, y1, y2) {
      return Math.abs(x1 - x2) >= Math.abs(y1 - y2)
        ? x1 - x2 > 0
          ? 'left'
          : 'right'
        : y1 - y2 > 0
          ? 'up'
          : 'down'
    },
    // 节流
    debounce (fn, delay) {
      // 维护一个 timer，用来记录当前执行函数状态
      let timer = null
      return function () {
        // 通过 ‘this’ 和 ‘arguments’ 获取函数的作用域和变量
        const context = this
        const args = arguments
        // 清理掉正在执行的函数，并重新执行
        clearTimeout(timer)
        timer = setTimeout(function () {
          fn.apply(context, args)
        }, delay)
      }
    }
  }
}
