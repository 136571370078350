<template>
  <div class="preview-modal">
    <div class="paper-wrapper" :style="wrapperStyle">
      <!-- <div class="ql-editor"
             v-html="htmlStr"></div> -->
      <quill-editor
        ref="editor"
        :style="paperStyle"
        id="preview-editor"
        :content="htmlStr"
        :options="editorOption"
      >
        <!-- 编辑器工具栏 -->
        <div id="preview-editor-toolbar" slot="toolbar"></div>
      </quill-editor>
    </div>
    <div class="control-panel">
      <div class="zoom-out" @click="zoomOut">
        <i class="iconfont icon-fangda"></i>
      </div>
      <div class="zoom-in" @click="zoomIn">
        <i class="iconfont icon-suoxiao"></i>
      </div>
      <div class="exit" @click="exit">
        <i class="iconfont icon-ios-my-exit"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'preview-box',
  data() {
    return {
      scale: 1,
      // A4纸张的像素大小
      baseWidth: 794,
      baseMinHeight: 1123,
      margin: 40,
      editorOption: {
        modules: {
          toolbar: '#editor-toolbar',
          table: true,
        },
      },
    }
  },
  props: {
    htmlStr: String,
  },
  computed: {
    dpr() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return parseInt(dpr)
    },
    editor() {
      return this.$refs.editor.quill
    },
    wrapperStyle() {
      let width =
        (this.dpr <= 2 ? this.baseWidth : (this.dpr / 2) * this.baseWidth) +
        this.margin * 2
      let minHeight =
        (this.dpr <= 2 ? this.baseMinHeight : (this.dpr / 2) * this.baseMinHeight) +
        this.margin * 2
      // const whPr = document.documentElement.clientHeight / document.documentElement.clientWidth
      const editorHeight = this.$refs.editor
        ? this.$refs.editor.$el.clientHeight
        : this.baseMinHeight
      const editorWidth = this.$refs.editor
        ? this.$refs.editor.$el.clientWidth
        : this.baseMinHeight
      if (this.scale > 1) {
        width = editorWidth * this.scale * 1.3
        minHeight = editorHeight * this.scale * 1.3
      }
      return {
        width: `${width}px`,
        minHeight: `${minHeight}px`,
      }
    },
    // A4纸张样式
    paperStyle() {
      const width = this.baseWidth
      const minHeight = this.baseMinHeight
      return {
        transform: `scale(${this.scale})`,
        width: `${width}px`,
        minHeight: `${minHeight}px`,
        // transformOrigin: `0 0 0`,
        padding: `${37.8 * 2}px`,
        border: 'none',
      }
    },
  },
  methods: {
    exit() {
      this.$emit('closePreview')
    },
    zoomOut() {
      this.scale += 0.2
    },
    zoomIn() {
      if (this.scale > 0.20000000000000007) {
        this.scale -= 0.2
      } else {
        this.$notify('已经达到最小缩放比例')
      }
    },
  },
  mounted() {
    this.editor.disable()
  },
}
</script>

<style lang="stylus">
.preview-modal
  z-index 999
  width 100%
  height 100%
  background #fff
  position fixed
  overflow scroll
  will-change height width
  transition transform 0.2s ease-in
  .paper-wrapper
    overflow scroll
    height 100%
    width 100%
    display flex
    justify-content center
    align-items center
    // .A4
    // margin 0 auto
    // box-shadow 0px 3px 15px 1px rgba(204, 204, 204, 0.45)
    // position relative
    // will-change transform
    // transition transform 0.2s ease-in
  .control-panel
    z-index 3
    position fixed
    right 0
    top calc(50% - 110px)
    width 50px
    height 220px
    display flex
    flex-direction column
    justify-content space-around
    align-items center
    background rgba(255, 255, 255, 0.8)
    box-shadow 0px 3px 15px 1px rgba(204, 204, 204, 0.45)
    div
      width 50px
      justify-content center
      display flex
      padding 10px 0
      align-items center
#preview-editor-toolbar
  height 0px
  width 0px
  border none
  margin 0
  padding 0
  color #34495e
#preview-editor
  will-change transform
  transition transform 0.2s ease-in
  box-shadow 0px 3px 15px 1px rgba(204, 204, 204, 0.45)
  .ql-container.ql-snow
    border none
</style>

<style>
#preview-editor .ql-editor {
  line-height: 2;
  tab-size: 0;
  font-size: 16px; /*no*/
  font-family: 'SimSun' !important;
}
#preview-editor .ql-editor .ql-indent-1 {
  padding-left: 2em;
}
#preview-editor .ql-editor p span {
  text-indent: initial;
}
</style>
