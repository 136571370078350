var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor-suggestion-panel" },
    [
      _c("fb-loading", { attrs: { loading: _vm.inSearching } }),
      _vm._l(_vm.customerList, function (item, i) {
        return _c(
          "van-cell",
          {
            key: i,
            staticClass: "company-cell",
            attrs: { title: item.customer.name },
            nativeOn: {
              click: function ($event) {
                return _vm.useCustomerItem(item)
              },
            },
          },
          [
            _c("van-icon", {
              staticClass: "custom-icon",
              attrs: { slot: "right-icon", name: "contact" },
              slot: "right-icon",
            }),
          ],
          1
        )
      }),
      _vm._l(_vm.companyList, function (item) {
        return _c("van-cell", {
          key: item.companyKey,
          attrs: { title: item.companyName },
          nativeOn: {
            click: function ($event) {
              return _vm.useCompanyItem(item)
            },
          },
        })
      }),
      _vm._l(_vm.lawList, function (item) {
        return _c(
          "div",
          {
            key: item.title,
            staticClass: "van-cell",
            attrs: { title: item.title },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.selectLawItem(item)
              },
            },
          },
          [_vm._v(_vm._s(item.title) + " ")]
        )
      }),
      _c("transition", { attrs: { name: "van-slide-right" } }, [
        _vm.lawObjectList && _vm.lawObjectList.length
          ? _c(
              "div",
              { staticClass: "law-object-list" },
              _vm._l(_vm.lawObjectList, function (item, i) {
                return _c(
                  "div",
                  {
                    key: item.law_id + i,
                    staticClass: "law-obj-item",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectLawObject(item)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "law-name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("span", { staticClass: "law-text" }, [
                      _vm._v(_vm._s(item.text)),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }