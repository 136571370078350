export default {
  data () {
    return {
      // 是否显示顶部面板
      showTopPanel: false,
      showPreviewModal: false,
      showSuggestionPanel: false,
      showAnalysisBox: false,
      showMask: false,
      showMoreMenu: false,
      showFontSizePicker: false,
      // 是否显示左侧面板
      showLeftPanel: false,
      // 是否显示右侧面板
      showRightPanel: false
    }
  },
  methods: {
    toggleMoreMenuPopup () {
      this.showMoreMenu = !this.showMoreMenu
    },
    showMoreMenuPopup () {
      this.showMoreMenu = true
    },
    hideMoreMenuPopup () {
      this.showMoreMenu = false
    },
    // 显示分析组件
    showAnalysis () {
      this.setTime(new Date())
      this.setAnaText(this.docPlain)
      this.showAnalysisBox = true
    },
    hideAnalysis () {
      this.showAnalysisBox = false
    },
    // 关闭蒙层
    hideMask () {
      this.showTopPanel = false
      this.showLeftPanel = false
      this.showMask = false
    },
    handelHideRightPanel () {
      this.showRightPanel = false
    },
    handelHideLeftPanel () {
      this.showLeftPanel = false
    },
    hideTopPanelAction () {
      this.showTopPanel = false
      this.showMask = false
    },
    showTopPanelAction () {
      this.editor.blur()
      this.showTopPanel = true
      // this.showMask = true
    },
    showLeftPanelAction () {
      this.editor.blur()
      this.showRightPanel = false
      this.showLeftPanel = true
    },
    showRightPanelAction () {
      this.editor.blur()
      this.showLeftPanel = false
      this.showRightPanel = true
    },
    // 显示搜索建议面板
    openSuggestionPanel () {
      this.showSuggestionPanel = true
      this.scrollEditorByPx(this.suggestionPanelHeight)
    },
    closeSuggestionPanel () {
      this.showSuggestionPanel = false
    }
  }
}
