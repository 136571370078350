import { Quill } from 'vue-quill-editor'
import QuillTabel from 'quill-table'
const { TableCell, TableRow, Table, Contain, TableModule } = QuillTabel
const Parchment = Quill.import('parchment')
const Inline = Quill.import('blots/inline')

// line-height
class LineHeightAttributor extends Parchment.Attributor.Style {
  value (domNode) {
    return super.value(domNode)
  }
}
const LineHeight = new LineHeightAttributor('line-height', 'line-height', {
  scope: Parchment.Scope.BLOCK
})

// font-family
class FontFamilyAttributor extends Parchment.Attributor.Style {
  value (domNode) {
    return super.value(domNode)
  }
}
const FontFamily = new FontFamilyAttributor('font-family', 'font-family', {
  scope: Parchment.Scope.BLOCK
})

// text-indent
class TextIndentAttributor extends Parchment.Attributor.Style {
  value (domNode) {
    return super.value(domNode)
  }
}
const TextIndent = new TextIndentAttributor('text-indent', 'text-indent', {
  scope: Parchment.Scope.BLOCK
})
// marginTop
class MarginTopAttributor extends Parchment.Attributor.Style {
  value (domNode) {
    return super.value(domNode)
  }
}
const MarginTop = new MarginTopAttributor('margin-top', 'margin-top', {
  scope: Parchment.Scope.BLOCK
})
// marginTop
class MarginBottomAttributor extends Parchment.Attributor.Style {
  value (domNode) {
    return super.value(domNode)
  }
}

// 自定义标签
class CustomSelect extends Inline {
  static formats (domNode) {
    return this.tagName.indexOf(domNode.tagName) + 1
  }
}
CustomSelect.blotName = 'custom'
CustomSelect.tagName = ['custom']

const MarginBottom = new MarginBottomAttributor(
  'margin-bottom',
  'margin-bottom',
  {
    scope: Parchment.Scope.BLOCK
  }
)

// font-size
class FontSiseAttributor extends Parchment.Attributor.Style {
  value (domNode) {
    return super.value(domNode)
  }
}
const FontSize = new FontSiseAttributor('font-size', 'font-size', {
  scope: Parchment.Scope.INLINE
})

Quill.register(FontSize)
Quill.register(LineHeight)
Quill.register(FontFamily)
Quill.register(MarginTop)
Quill.register(MarginBottom)
Quill.register(TextIndent)
Quill.register(CustomSelect)
// 表格
Quill.register(TableCell)
Quill.register(TableRow)
Quill.register(Table)
Quill.register(Contain)
Quill.register('modules/table', TableModule)

export default Quill
